export default {
  error_occurred: 'Žal je prišlo do napake. Poskusite znova čez trenutek.',
  new_content_available: 'Prišlo je do nove posodobitve strani. Za ponovno nalaganje strani kliknite »Osveži«.',
  refresh_button_title: 'Osveži',
  force_reload_message: 'Žal je prišlo do napake. Naša ekipa že dela na tem. Čez trenutek poskusite znova naložiti spletno mesto.',
  sign_up: 'Ustvari račun',
  service: 'Storitev',
  employee: 'Zaposleni',
  select_employee: 'Izberite osebo',
  employees: 'Zaposleni',
  services: 'Storitve',
  duration: 'Trajanje',
  from: 'Od',
  select_date: 'Izberite datum',
  no_account_question: 'Še nimate računa?',
  name: 'Ime',
  surname: 'Priimek',
  name_and_surname: 'Polno ime',
  phone: 'Telefon',
  password: 'Geslo',
  field_is_required: 'To polje je obvezno.',
  value_is_too_long: 'Vrednost je predolga.',
  value_is_too_short: 'Vrednost je prekratka.',
  signup_was_successful: 'Registracija je bila uspešna. Prijava v ploščo.',
  signup_has_failed: 'Registracija ni uspela. Poskusite znova čez trenutek.',
  email_is_taken: 'Račun z navedenim e-poštnim naslovom že obstaja. Če ste pozabili geslo, uporabite možnost opomnika.',
  email_or_password_incorrect: 'E-poštni naslov ali geslo ni pravilno. Če ste pozabili geslo, uporabite možnost opomnika.',
  account_not_found: 'Račun ni bil najden.',
  page_not_found: 'Ups! Strani, ki ste jo iskali, ni bilo mogoče najti :(',
  go_back_to_main_page: 'Vrnite se na domačo stran in poskusite znova.',
  bookings: 'rezervacije',
  account: 'Moj račun',
  date: 'Datum',
  cancel: 'Prekliči',
  date_from: 'Datum, od',
  date_to: 'Datum do',
  nothing_found_here: 'Nič ni bilo najdeno.',
  old_password: 'Staro geslo',
  remove: 'Izbriši',
  phone_is_invalid: 'Telefonska številka ni pravilna.',
  book: 'Knjiga',
  sign_up_2: 'Ustvari račun',
  log_in: 'Prijava',
  your_email_address: 'Vaš email naslov',
  select: 'Izberite',
  filters: 'Filtri',
  expand: 'Razširi',
  collapse: 'Strni',
  bookings_drawer_title: 'Izberite filtre za prikaz samo rezervacij, ki jih iščete.',
  clear_filters: 'Ponastavi filtre',
  show_more: 'Pokaži več',
  dont_you_remember_password: 'Ste pozabili geslo?',
  password_information: 'Geslo mora biti sestavljeno iz vsaj 8 znakov, vsebovati mora veliko črko, številko in poseben znak.',
  forgotten_password_button: 'Pošljite povezavo za spremembo gesla',
  do_you_already_have_an_account: 'Ali že imate račun?',
  reset_password_title: 'Nastavite novo geslo',
  reset_password_subtitle: 'Spodaj vnesite svoje novo geslo.',
  reset_password_button: 'Shranite novo geslo',
  profile: 'Profil',
  logout: 'Odjava',
  cancel_booking: 'Prekliči svojo rezervacijo',
  status_approved: 'Odobreno',
  status_payment: 'Čakanje na plačilo',
  status_done: 'Dokončano',
  status_cancelled: 'Prekinjeno',
  cancel_booking_dialog_title: 'Ali želite preklicati svojo rezervacijo?',
  cancel_booking_dialog_information: 'Tega dejanja ni mogoče razveljaviti!',
  cancel_booking_dialog_button: 'Da, prekliči mojo rezervacijo',
  my_profile: 'Moj profil',
  my_data: 'Moji podatki',
  upload_new_photo: 'Dodajte novo fotografijo',
  birthday: 'rojstni dan',
  save_changes: 'Shrani spremembe',
  phone_number: 'Telefonska številka',
  directional: 'Področna koda',
  save_new_password: 'Shranite novo geslo',
  new_password: 'Novo geslo',
  password_change: 'Spremeni geslo',
  view: 'Pogled',
  services_2: 'Storitve',
  accept_terms_and_conditions_1: 'S klikom na spodnjo možnost se strinjam z',
  accept_terms_and_conditions_2: 'Pogoji',
  accept_terms_and_conditions_3: 'in potrjujem, da sem prebral',
  accept_terms_and_conditions_4: 'Izjava o zasebnosti.',
  to: 'Za',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Prijava',
  email_is_incorrect: 'E-poštni naslov ni pravilen.',
  logged_out: 'Odjavljeni ste bili',
  booking_dialog_date_is_already_taken: 'Izbrani čas ni na voljo. Bi izbrali drugo mesto?',
  data_search: 'Iskanje podatkov. Prosim počakaj...',
  no_employees: 'Brez zaposlenih',
  no_services: 'Brez storitev',
  no_categories: 'Brez kategorij',
  no_bookings: 'Rezervacije niso bile najdene.',
  no_subscriptions: 'Najdena ni bila nobena naročnina.',
  female: 'ženska',
  male: 'Moški',
  other: 'Drugo',
  no_numeric_in_password: 'V geslu ni številk.',
  no_lowercase_in_password: 'V geslu ni male črke.',
  no_uppercase_in_password: 'V geslu ni velikih črk.',
  no_special_character: 'Brez posebnega značaja.',
  min_n_characters: 'Najmanjše število znakov je @{min}.',
  max_n_characters: 'Največje dovoljeno število znakov je @{max}.',
  email: 'E-naslov',
  reset_password_fail_text: 'Geslo ni spremenjeno. Povezava je verjetno potekla.',
  reset_password_success_title: 'Vaše geslo je bilo spremenjeno.',
  reset_password_success_text: 'Prijavite se lahko z novim geslom.',
  account_activation: 'Aktivacija računa',
  account_activation_success: 'Račun je aktiviran.',
  account_activation_fail: 'Pri aktiviranju vašega računa je prišlo do težave. Videti je, da je račun že aktiven ali pa povezava ni več veljavna.',
  account_activation_processing: 'Aktiviranje vašega računa. Prosim počakaj...',
  account_activation_resend_title: 'Račun ni bil aktiviran',
  account_activation_resend_text: 'Aktivirajte svoj račun s klikom na povezavo za aktivacijo v e-poštnem sporočilu, ki smo vam ga poslali.',
  account_activation_resend_button: 'Pošlji ponovno',
  confirm_send_activate_link_dialog_subtitle: 'Na prej navedeni e-poštni naslov smo poslali e-poštno sporočilo s povezavo za aktivacijo.',
  file_is_too_large: 'Izbrana datoteka je prevelika.',
  appointment_title: 'Izberite osebo, datum in uro rezervacije',
  others: 'drugo',
  countryLabel: 'Država',
  requiredMessages: 'Zahtevana je telefonska številka',
  date_of_visit: 'Datum',
  hour_of_visit: 'Čas',
  thank_you_for_booking: 'Vaša rezervacija',
  no_free_hours: 'Na izbrani dan ni razpoložljivih terminov.',
  any_person: 'Naključna izbira',
  password_changed: 'Vaše geslo je bilo spremenjeno',
  incorrect_old_password: 'Staro geslo ni pravilno',
  error404: 'Napaka 404',
  minutes: 'Min',
  hours: 'h',
  days: 'd',
  ok: 'v redu',
  web_page_temporary_disabled: 'Oprostite! Ta stran je bila začasno onemogočena.',
  profile_update_success: 'Vaši podatki so shranjeni.',
  form_is_invalid: 'Obrazec vsebuje napake. Preverite označena polja in poskusite znova.',
  search: 'Iskanje',
  pay_online_button_title: 'Plačajte na spletu',
  account_not_activated_info: 'E-poštni naslov ni bil potrjen. Potrdite svoj elektronski naslov s klikom na povezavo v poslanem sporočilu.',
  paid: 'Plačano',
  payments: 'Plačila',
  payment_service: 'Storitev',
  payment_date: 'Datum',
  payment_price: 'Cena',
  payment_success_title: 'Plačilo je bilo uspešno, hvala!',
  payment_fail_title: 'Plačilo ni uspelo, poskusite znova.',
  pay_again: 'Ponovno plačaj',
  newsletter_subscribe_success: 'Hvala vam! Vaš e-poštni naslov je bil potrjen.',
  newsletter_subscribe_fail: 'Pri aktiviranju vašega e-poštnega naslova je prišlo do težave. Videti je, da je e-poštni naslov že aktiven ali pa povezava ni več veljavna.',
  newsletter_send_again: 'Pošlji ponovno',
  newsletter_send_again_success: 'Aktivacijska povezava je bila ponovno poslana.',
  send_again_fail: 'Pri ustvarjanju nove aktivacijske povezave je prišlo do težave, morda je vaš e-poštni naslov že aktiven ali pa ste pravkar ustvarili povezavo.',
  newsletter_email_is_used: 'E-poštni naslov je že na seznamu, če naslov ni potrjen, lahko ponovno pošljemo aktivacijsko povezavo.',
  newsletter_unsubscribe_success: 'Hvala vam! Vaš e-poštni naslov je bil odstranjen.',
  newsletter_unsubscribe_fail: 'Pri brisanju e-poštnega naslova je prišlo do težave. Videti je, da je bil e-poštni naslov že izbrisan ali da je povezava potekla.',
  booking_payments: 'rezervacije',
  simple_store_product_transactions: 'Izdelki',
  payment_type_select_box: 'Izberite vrsto plačila',
  payment_description: 'Opis',
  add_to_cart: 'Dodaj v voziček',
  remove_from_cart: 'Odstrani iz vozička',
  save: 'Shrani',
  bookings_cart_summary: 'Povzetek rezervacije',
  today: 'Danes',
  fill_booking_details_title: 'Vnesite podrobnosti',
  create_an_account_question: 'Ustvarite račun in pošljite geslo na elektronski naslov',
  value_is_incorrect: 'Ta vrednost ni veljavna.',
  first_available_date: 'Razpoložljivi termini so vključeni',
  service_location_1: 'Naslov',
  service_location_2: 'Telefon',
  service_location_3: 'Google Meet',
  service_location_4: 'Drugo',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Lokacija',
  booking_not_found: 'Rezervacije ni mogoče najti.',
  booking_canceled: 'Rezervacija je bila preklicana.',
  booking_canceled_fail: 'Te rezervacije ni mogoče preklicati.',
  day: 'Dan',
  week: 'Teden',
  month: 'Mesec',
  year: 'Leto',
  booking_plural_1: 'Rezervacija',
  booking_plural_2: 'rezervacije',
  booking_plural_3: 'rezervacije',
  booking_plural_4: 'rezervacije',
  booking_plural_5: 'rezervacije',
  day_plural_1: 'Dan',
  day_plural_2: 'Dnevi',
  day_plural_3: 'Dnevi',
  day_plural_4: 'Dnevi',
  day_plural_5: 'Dnevi',
  week_plural_1: 'Teden',
  week_plural_2: 'Tedne',
  week_plural_3: 'Tedne',
  week_plural_4: 'Tedne',
  week_plural_5: 'Tedne',
  month_plural_1: 'Mesec',
  month_plural_2: 'Meseci',
  month_plural_3: 'Meseci',
  month_plural_4: 'Meseci',
  month_plural_5: 'Meseci',
  year_plural_1: 'Leto',
  year_plural_2: 'Leta',
  year_plural_3: 'Leta',
  year_plural_4: 'Leta',
  year_plural_5: 'Leta',
  tax: 'ID za DDV',
  tax_prefix: 'Predpona',
  get_company_data: 'Iskanje',
  get_company_data_failed: 'Podatkov ni bilo mogoče prenesti.',
  company_name: 'Ime podjetja',
  street_and_number: 'Ulica in številka',
  postcode: 'Poštna številka',
  city: 'Mesto',
  country: 'Država',
  subscribe: 'Naročite se',
  recurrent_fee_info: 'Zaračunano vsak',
  one_time_fee_info: 'Enkratna pristojbina.',
  new_account_title: 'Ustvari nov račun',
  payment_data_title: 'Podatki o plačilu',
  billing_data_checkbox_question: 'Podrobnosti za obračun',
  confirmation_modal_header: 'Dejanje zahteva potrditev',
  confirmation_modal_warning: 'Ste prepričani, da želite nadaljevati?',
  no_way_to_undo_action: 'Tega dejanja ni mogoče razveljaviti!',
  delete_payment_method: 'Odstrani kartico',
  add_payment_method: 'Dodaj kartico',
  fill_card_details: 'Izpolnite podatke o plačilu.',
  subscriptions: 'Naročnine',
  subscription: 'Naročnina',
  invoice_number: 'Račun',
  number: 'Številka',
  status: 'Stanje',
  details: 'Podrobnosti',
  created_at: 'Ustvarjeno',
  subscription_status_initiated: 'Neplačano',
  subscription_status_active: 'Aktiven',
  subscription_status_inactive: 'Neaktiven',
  subscription_status_canceled: 'Prekinjeno',
  ends_at: 'Konča se ob',
  ends_at_default: 'Velja do konca obračunskega obdobja',
  select_subscription_to_pay: 'Izberite naročnino za plačilo.',
  pay_with_subscription: 'Plačajte z naročnino',
  bookings_paid_by_subscription_success_message: 'Plačano',
  bookings_not_paid_by_subscription_error_message: 'Z izbrano naročnino rezervacije ni bilo mogoče plačati.',
  stripe: 'Stripe',
  provider_tag: 'Metoda',
  card: 'Kartica',
  cash: 'Gotovina',
  transfer: 'Prenos',
  promo_code_label: 'Koda za popust',
  promo_code_invalid_message: 'Navedena koda za popust je neveljavna.',
  payment_method_setup_error: 'Prišlo je do težave z vašo kartico. Vaša banka je zavrnila avtorizacijo kartice za kasnejše transakcije. Obrnite se na svojo banko.',
  active_subscription_missing: 'Za rezervacijo te storitve je potrebna aktivna naročnina. Na žalost nimate nobene naročnine za uporabo.',
  area_code: 'Področna koda',
  usage: 'Uporaba',
  create_account_in_stripe_failed: 'Plačilni operater je zavrnil vaše naročniške podatke. Prosimo, da preverite pravilnost obrazca (davčna številka, telefonska številka, e-naslov).',
  additional_auth_required: 'Vaša banka zahteva dodatno avtorizacijo',
  general_payment_error: 'Prišlo je do težave z vašim plačilom. Morda vaša kartica ne podpira naročnin, ima omejitve nakupov ali nima dovolj sredstev za plačilo naročnine. Obrnite se na svojo banko ali izberite drug način plačila.',
  subscription_set_successfully: 'Naročnina pravilno nastavljena.',
  booking_created: 'Nova rezervacija',
  booking_updated: 'Sprememba rezervacije',
  booking_deleted: 'Izbris rezervacije',
  booking_reminder: 'Opomnik za rezervacijo',
  email_notifications: 'E-poštna obvestila',
  sms_notifications: 'SMS obvestila',
  save_user_preferences: 'Shrani nastavitve',
  free_spots: 'Ostala mesta',
  no: 'Št',
  yes: 'Ja',
  p24_inactive: 'Plačilnega operaterja Przelewy24 ni mogoče uporabiti. Obrnite se na svojega plačilnega operaterja.',
  stripe_customer_invalid: 'Neveljaven ID stranke Stripe. Načina plačila ni mogoče vezati na stranko.',
  stripe_resource_invalid: 'Izbrani izdelek ni na voljo. Dobavljivost izdelkov preverite pri plačilnem operaterju.',
  account_blocked_message: 'Vaš račun je neaktiven. Prosimo kontaktirajte skrbnika.',
  time_zone: 'Časovni pas',
  no_spots_left: 'Dosegli ste največje število razpoložljivih mest.',
  employee_auth_required: 'Prijavite se v svoj račun ali uporabite drug e-poštni naslov. Računi zaposlenih zahtevajo prijavo.',
  subpage_auth_required: 'Za ogled zavihka se prijavite v svoj račun.',
  gross: 'Bruto znesek.',
  subscription_login_info: 'Že imate račun? Prijavite se spodaj, da vnesete svoje podatke.',
  change_date_time: 'Prerazporedite',
  promo_code_checkbox: 'Imam kodo za popust',
  booking_promo_code_label: 'Vnesite kodo za popust',
  select_hour: 'Izberite čas',
  id: 'Id',
  booking_rescheduled: 'Vaša rezervacija je bila spremenjena.',
  booking_rescheduled_fail: 'Te rezervacije ni mogoče spremeniti.',
  max_user_bookings_error: 'Presegli ste največje število rezervacij. Izberite drugo storitev ali nas kontaktirajte.',
  in_total: 'Skupaj',
  company: 'Podjetje',
  individual: 'Zasebna oseba',
  bulk_payment: 'Množično plačilo',
  make_bulk_payment: 'Množično plačilo',
  simple_store_product_confirmation_title: 'Izpolnite obrazec za nakup',
  select_service_type: 'Izberite možnost storitve',
  add_to_calendar_button_label: 'Dodaj v koledar',
  login_to_use_service_with_subscription: 'Prijavite se v svoj račun, da rezervirate termin za to storitev.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Izbrani datum ni na voljo. Prosimo, izberite drug prost datum. V primeru rezervacije več datumov se prepričajte, da si datumi medsebojno ne nasprotujejo.',
  select_location_calendar_warning: 'Izberite lokacijo za nalaganje razpoložljivih terminov.',
  selectable_location_online_title: 'Spletno',
  change: 'Spremeni',
  show_less_hours_button: 'Pokaži manj ur',
  show_more_hours_button: 'Pokaži več ur',
  add_another_button_title: 'Dodaj še enega',
  close: 'Zapri',
  selected_time_slots: 'Izbrani časovni termini',
  select_location: 'Izberite lokacijo',
  captcha_invalid_error_notification: 'Captcha preverjanje ni bilo uspešno. Poskusite osvežiti stran, če se težava nadaljuje.',
  verify_code_incorrect_error: 'Vnesena koda je napačna.',
  verify_code_too_many_requests: 'Trenutno ne moremo ustvariti nove kode. Poskusite znova kasneje ali uporabite kodo, ki smo vam jo poslali.',
  send_again_button_title: 'Pošlji znova',
  verify_title: 'Preveri',
  verify_code_title: 'Vnesite potrditveno kodo',
  verify_code_description: 'Poslali smo vam 6-mestno potrditveno kodo na vaš e-poštni naslov. Prosimo, vnesite jo spodaj:',
  verification_invalid_error_notification: 'Neveljavna preveritev. Poskusite znova pozneje.'
}
